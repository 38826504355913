<template>
  <div class="row">

    <div class="col-sm-12">
      <package-and-sets-filter :data-from-parent="sets"></package-and-sets-filter>
      <p class="text text_italic text_green" v-if="sets.length < 1">You have not created any set yet. Please click the
        button to create a set.</p>
    </div>

    <div class="setWrap">
      <div class="set"
           v-for="item in dataItems"
           :key="item.id"
           :style="{maxWidth: 'calc(' + styleFlex(item.packages)  + ' - 20px)'}"
      >
        <div class="set__titleBox">
          <h3 class="set__title text_green" :title="item.name">{{item.name}}</h3>
          <h4 class="set__subTitle">{{item.type_name}} | {{item.style_name}} | {{item.grade_name}}</h4>
        </div>

        <div class="set__item"
             v-for="p in item.packages"
             :key="p.id"
        >
          <h5 class="set__itemTitle text text_green">
            <span :title="p.name">
              {{p.name}}
            </span>

            <span class="set__itemDelete" :disabled="!item.can_edit" @click="openDelModal(p.id, item)">
               <svg-icon name="times"/>
            </span>

            <span class="set__itemDelete" :disabled="!item.can_edit" @click="editPackage(item, p)">
              <svg-icon name="edit"/>
            </span>

          </h5>

          <div class="toggleList" v-if="checkLength(p.options)">
            <strong>Options:</strong>
            <ul>
              <li v-for="option in p.options"
                  :class="{'custom': option.custom}"
              >
                <svg-icon name="check-square" v-if="option.custom"/>
                {{option.name}}
              </li>
            </ul>
          </div>

          <div class="toggleList" v-if="checkLength(p.accessories)">
            <strong>Accessories:</strong>
            <ul>
              <li v-for="acc in p.accessories"
                  :key="acc.id"
              >{{acc.get_item.name}}
              </li>
            </ul>
          </div>

        </div>

        <div v-if="item.can_edit" class="thumbnailsTable" @click="addPackage(item)">
                <span class="thumbnailsTable__img">
                  <svg-icon name="plus-circle" class="large_icon"/>
                </span>
          <span class="thumbnailsTable__text">Add new package</span>
        </div>

        <div class="set__footer">
          <button class="btn btn_default btn_full" type="button"
                  @click="delSet(item)"
                  :disabled="!item.can_edit"
          >Remove
          </button>
        </div>

      </div>

      <div class="thumbnailsTable">
        <span class="thumbnailsTable__img" @click="nextStep">
          <svg-icon name="plus-circle" class="large_icon"/>
        </span>
        <span class="thumbnailsTable__text" @click="nextStep">Create New Set</span>
      </div>
    </div>
    <div>
      <remove-modal :setId="setId" v-if="showRemoveModal"></remove-modal>
    </div>

    <transition name="fade">
      <div class="modal modal_wrap" v-show="selectModal">
        <div class="modal_popup modal_full">

          <select-type :createdOrder="false" :isSet="true" v-if="currentRouteName === 'sets-builder'"
                       class="modal_content"></select-type>

          <select-style v-else-if="currentRouteName === 'sets-builder-type'" class="modal_content"></select-style>

          <select-grade v-else-if="currentRouteName === 'sets-builder-style'" class="modal_content"></select-grade>

          <select-options-to-set
            v-else-if="currentRouteName === 'sets-builder-choice'"
            class="modal_content"
            :order="false"
            :isSet="true"
            :appendPackage="appendPackage"
          ></select-options-to-set>

          <make-set-buttons v-if="currentRouteName === 'sets-builder-grade'" class="modal_content"></make-set-buttons>

          <button class="btn btn_transparent" @click="openWarning"><svg-icon name="solid/times" /> Cancel</button>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <warning @close="closeSelect" v-if="warningModal"/>
    </transition>

    <transition name="fade">
      <delete @delete="openDelModal" :id="packageId" :package="true"/>
    </transition>
  </div>
</template>

<script>
  import router from "../../router";
  import {mapGetters} from "vuex";
  import {defineAsyncComponent} from "vue";

  const SelectType = defineAsyncComponent(() => import('../orders/SelectType'));
  const SelectStyle = defineAsyncComponent(() => import('../orders/SelectStyle'));
  const SelectGrade = defineAsyncComponent(() => import('../orders/SelectGrade'));
  const SelectColors = defineAsyncComponent(() => import('../orders/SelectColors'));
  const SelectOptionsToSet = defineAsyncComponent(() => import('../orders/SelectOptionsToSet'));
  const MakeSetButtons = defineAsyncComponent(() => import("../orders/sets/MakeSetButtons"));
  const PackageAndSetsFilter = defineAsyncComponent(() => import('./PackageAndSetsFilter'));
  const RemoveModal = defineAsyncComponent(() => import("../orders/sets/RemoveModal"));

  const Warning = defineAsyncComponent(() => import('../modals/WarningModal'));
  const Delete = defineAsyncComponent(() => import('../modals/DeleteModal'));

  export default {
    name: "Sets",

    components: {
      MakeSetButtons,
      PackageAndSetsFilter,
      RemoveModal,
      Delete,
      Warning,

      SelectType,
      SelectStyle,
      SelectGrade,
      SelectColors,
      SelectOptionsToSet
    },


    data() {
      return {
        packageId: 0,
        modals: {
          selectType: false,
          selectStyle: false,
          selectGrade: false,
          selectColors: false,
          selectOptions: false,
          makeSetButtons: false,
        },
        exchangeData: '',
        currentRouteName: '',
        appendPackage: false,
        showRemoveModal: false,
        setId: Number,
        dataItems: [],

        windowWidth: window.innerWidth,
      }
    },
    watch: {
      '$route'(to, from) {
        let oldPath = from.path,
          newPath = to.path,
          oldRoute = _.split(_.trimStart(oldPath, '/'), '/'),
          newRoute = _.split(_.trimStart(newPath, '/'), '/');

        if (newRoute[0] === 'sets-builder') {
          if (newRoute.length < oldRoute.length) {
            this.$store.dispatch('app/popBreadcrumb');
          }
        }
        this.currentRouteName = to.name;
      },
      exchangeData() {
        _.forEach(this.modals, (v, k) => {
          this.modals[k] = false
        });

        this.modals[this.exchangeData] = true
      },
      sets(n) {
        this.dataItems = n;
      },


    },

    computed: {

      selectModal() {
        return this.$store.getters['createdOrder/selectModal'];
      },

      warningModal() {
        return this.$store.getters['createdOrder/warningModal'];
      },

      sets() {
        return this.$store.getters['sets/sets'];
      },

      ...mapGetters({
        warningModal: 'createdOrder/warningModal',
      })

    },

    methods: {
      editPackage(set, packageData) {
        if (!set.can_edit) {
          return false;
        }
        this.$store.commit('packages/setIsEdit', true);
        this.$store.commit('packages/setIsEditFromSet', true);
        this.$store.commit('sets/setPackageName', packageData.name);
        this.$store.dispatch('sets/prepareEditPackage', {
          set: set,
          package: packageData
        });
      },

      styleFlex(elem) {
        let elems = Object.keys(elem).length;

        if (this.windowWidth > 992) {
          if (!elems || elems < 1) {
            return 25 + '%'
          }

          else if (elems >= 3) {
            return 100 + '%'
          }

          else {
            return parseFloat((100 / 4) * elems) + '%';
          }
        }

        if (this.windowWidth > 550) {
          if (!elems || elems < 1) {
            return 50 + '%'
          }

          else {
            return parseFloat((100 / 2) * elems) + '%';
          }
        }

        else {
          return 100 + '%'
        }

      },

      addPackage(set) {
        this.appendPackage = true;
        this.$store.commit('sets/set', {k: 'setId', v: _.toInteger(set.id)});
        this.$store.commit('sets/setPackageName', '');
        this.$store.commit('packages/setIsEditFromSet', true);

        this.$store.commit('createdOrder/updateType', _.toInteger(set.type));
        this.$store.commit('createdOrder/updateStyle', _.toInteger(set.style_id));
        this.$store.commit('createdOrder/updateGrade', _.toInteger(set.grade_id));
        this.$store.commit('sets/set', {k: 'isSetState', v: false});
        this.$store.commit('packages/setIsEdit', false);

        // this.$store.dispatch('options/getOptions');

        this.$store.commit(
          'app/set',
          {
            k: 'breadcrumbs',
            v: [
              {
                route: {
                  path: '/sets-builder/'
                },
                name: set.name,
                disabled: true,
              },
              {
                route: {
                  path: '/sets-builder/' + set.type
                },
                name: set.type_name,
                disabled: true,
              },
              {
                route: {
                  path: `/sets-builder/${set.type}/${set.style_id}`
                },
                name: set.style_name,
                disabled: true,
              },
              {
                route: {
                  path: `/sets-builder/${set.type}/${set.style_id}/${set.grade_id}`
                },
                name: set.grade_name,
                disabled: true,
              },
            ]
          }
        );

        router.push({path: '/sets-builder-choice'});
        this.exchangeData = 'selectOptions';
        this.$store.commit('createdOrder/selectModal', true);
      },

      setRoute() {
        this.currentRouteName = this.$router.currentRoute.name;
      },

      checkRoute() {
        if (this.$router.name !== 'sets' && !this.show) {
          this.$router.push({name: 'sets'})
        }
      },

      delSet(item) {
        if (Object.keys(item.packages).length > 0) {
          this.setId = _.toInteger(item.id);
          this.showRemoveModal = true;
        } else {
          this.$store.dispatch('sets/delete', {id: _.toInteger(item.id), with_packages: false});
          this.$store.dispatch('sets/getSets');
        }
      },

      nextStep() {
        this.appendPackage = false;
        this.$store.commit('createdOrder/selectModal', true);
        this.$store.commit('packages/setIsEditFromSet', true);
        this.$store.commit('packages/setIsEdit', false);

        this.$store.commit(
          'app/set',
          {
            k: 'breadcrumbs',
            v: [{
              route: {
                path: '/sets-builder'
              },
              name: 'Enter Set Name',
            }]
          }
        );
        router.push({path: '/sets-builder'})
      },

      checkLength(obj) {
        if (typeof obj === 'object' && Object.keys(obj).length > 0) {
          return true;
        }
        return false;
      },

      openWarning() {
        this.$store.commit('createdOrder/warningModal', true);
      },

      closeSelect() {
        this.$store.commit('createdOrder/selectModal', false);
      },

      openDelModal(id, set) {
        if (set === undefined || !set.can_edit) {
          return false;
        }
        this.packageId = id;
        this.$store.commit('createdOrder/deleteModal', true);
      },
    },

    created() {
      this.setRoute();
      this.$store.dispatch('sets/getSets');
      this.checkRoute();
    },

    mounted() {
      window.onresize = (event) => {
        this.windowWidth = window.innerWidth;
      };
    }
  }
</script>

<style scoped lang="scss">

  @import "../../assets/scss/utils/vars";

  ul {
    li {
      list-style: disc inside;
      display: list-item;
      &.custom {
        list-style-type: none;
        svg {
          color: $green;
        }
      }
    }
  }

  .thumbnailsTable {
    padding: 0 1rem;
    margin-bottom: 20px;
    width: calc(25% - 20px);
    min-width: 165px;
    align-items: center;
    justify-content: center;
  }

</style>
